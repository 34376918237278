// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-3-d-tsx": () => import("./../../src/pages/3d.tsx" /* webpackChunkName: "component---src-pages-3-d-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-vr-tsx": () => import("./../../src/pages/vr.tsx" /* webpackChunkName: "component---src-pages-vr-tsx" */)
}

